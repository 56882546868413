<!--
* @description:
* @fileName pageConfig.vue
* @author hvv
* @date 2022/01/13 15:21:21
!-->
<template>
  <div>
    <vab-query-form>
      <vab-query-form-left-panel :span="12">
        <el-button :icon="Plus" type="primary" @click="handleEdit($event)">
          添加
        </el-button>
        <!-- <el-button :icon="Delete" type="danger" @click="handleDelete($event)">
          批量删除
        </el-button> -->
      </vab-query-form-left-panel>
      <vab-query-form-right-panel :span="12">
        <el-form :inline="true" :model="queryForm" @submit.prevent>
          <el-form-item>
            <el-input v-model.trim="queryForm.name" clearable placeholder="请输入名称" />
          </el-form-item>
          <el-form-item>
            <el-button :icon="Search" type="primary" @click="queryData">
              查询
            </el-button>
          </el-form-item>
        </el-form>
      </vab-query-form-right-panel>
    </vab-query-form>
    <el-table v-loading="listLoading" border :data="list">
      <el-table-column label="模版ID" prop="id" show-overflow-tooltip />
      <el-table-column label="封面" prop="pageImage" width="250" />
      <el-table-column label="模版名称" prop="name" show-overflow-tooltip />
      <el-table-column label="是否为主页" prop="name" show-overflow-tooltip>
        <template #default="{ row }">
          <el-tag type="success" v-if="row.isMeetingHome">主页</el-tag>
          <el-tag v-else type="danger">/</el-tag>
        </template>
      </el-table-column>
      <el-table-column label="状态" prop="status" show-overflow-tooltip>
        <template #default="{ row }">
          <el-switch v-model="row.status" size="large" active-text="上线" inactive-text="下线" @change="shopStatusChange(row.id, row.status)" />
        </template>
      </el-table-column>
      <el-table-column label="操作" show-overflow-tooltip width="500">
        <template #default="{ row }">
          <el-button type="primary" @click="handleEdit(row)">
            <vab-icon icon="edit-2-line" />
            编辑
          </el-button>
          <el-button type="primary" @click="handleSetHomePage(row)">
            <vab-icon icon="settings-6-line" />
            设置主页
          </el-button>
          <el-button type="info" @click="handleConfig(row)">
            <vab-icon icon="settings-6-line" />
            配置
          </el-button>
          <el-button type="info" @click="handlePreview(row)">
            <vab-icon icon="settings-6-line" />
            分享
          </el-button>
          <el-button type="danger" @click="handleDelete(row)">
            <vab-icon icon="settings-6-line" />
            删除
          </el-button>
        </template>
      </el-table-column>
      <template #empty>
        <!-- <el-image
                class="vab-data-empty"
                :src="require('@/assets/empty_images/data_empty.png')"
              /> -->
        <el-empty class="vab-data-empty" description="暂无数据" />
      </template>
    </el-table>
    <el-pagination background :current-page="queryForm.pageNumber" :layout="layout" :page-size="queryForm.pageSize" :total="total" @current-change="handleCurrentChange" @size-change="handleSizeChange" />
    <edit ref="editRef" @fetch-data="fetchData" />

    <generateWxVue ref="generateWxRef"></generateWxVue>

  </div>
</template>

<script>
import {
  defineComponent,
  defineAsyncComponent,
  getCurrentInstance,
  onMounted,
  reactive,
  toRefs,
  onActivated,
} from 'vue'

import { Delete, Plus, Search } from '@element-plus/icons'
import { getPage, pageStatus, deletePage, setExhHomePage } from '@/api/page'
import { useRoute } from 'vue-router'
import generateWxVue from '@/components/generateWx/generateWx.vue'
export default defineComponent({
  name: 'exHibitionPageConfig',
  components: {
    Edit: defineAsyncComponent(() => import('./components/pageEdit')),
    generateWxVue,
  },
  setup() {
    const { proxy } = getCurrentInstance()

    const state = reactive({
      queryForm: { name: '', pageNumber: 1, pageSize: 10 },
      total: 0,
      listLoading: false,
      list: [],
      layout: 'prev, pager, next',
      editRef: null,
      generateWxRef: null,
      logo: '',
    })

    const route = useRoute()
    const handleEdit = (row) => {
      if (row.id) {
        state.editRef.showEdit(row)
      } else {
        state.editRef.showEdit()
      }
    }

    const handleConfig = (row) => {
      let url = `/exhibitionManage/exhibitionPageConfigModule?id=${row.id}&meetingCode=${route.query.meetingCode}`
      if (row.enterpriseCode) {
        url += `&enterpriseCode=${row.enterpriseCode}`
      }
      proxy.$router.push(url)
    }

    const handleDelete = (row) => {
      if (row.id) {
        proxy.$baseConfirm('你确定要删除当前项吗', null, async () => {
          const { msg } = await deletePage(row.id)
          proxy.$baseMessage(`操作成功`, 'success', 'vab-hey-message-success')
          await fetchData()
        })
      }
    }

    const queryData = () => {
      fetchData()
    }
    const handlePreview = (row) => {
      // getQrcodeUrls(row.code, 'HOLD_EXHIBITION').then((res) => {
      //   state.qrcodeUrl = res.data[0].qrcodeUrl
      //   refExPreview.value.handleOpen()
      // })
      console.log(row)
      let qrCodeParams = {
        autoColor: false,
        b: 0,
        env: '',
        g: 0,
        jumpPath: '/subPackage/index/digital-trade/digital-trade',
        jumpType: '',
        logo: state.logo,
        params: `{"navTitle":"${row.name}", pageId: "${row.id}", exhibitionCode: "${state.queryForm.meetingCode}"}`,
        path: 'pages/index/index',
        r: 0,
        scenesType: 'SUPPER_SCENES_TYPE',
        width: 1280,
        miniPath: `subPackage/index/digital-trade/digital-trade?navTitle=${row.name}&pageId=${row.id}&exhibitionCode=${state.queryForm.meetingCode}`,
      }
      state.generateWxRef.handleOpen(row, qrCodeParams)
    }

    const fetchData = async () => {
      state.listLoading = true
      state.queryForm.meetingCode = route.query.meetingCode
      state.logo = route.query.logo
      const { data } = await getPage(state.queryForm)
      state.list = data.list
      state.total = data.total
      state.listLoading = false
    }

    const handleSizeChange = (val) => {
      state.queryForm.pageSize = val
      fetchData()
    }
    const handleCurrentChange = (val) => {
      state.queryForm.pageNumber = val
      fetchData()
    }

    const shopStatusChange = async (id, status) => {
      let statusKey = status ? 'ACTIVATION' : 'OFFLINE'
      try {
        await pageStatus(id, statusKey)
        proxy.$baseMessage(`操作成功`, 'success', 'vab-hey-message-success')
      } catch (e) {
        console.log(e)
      }

      fetchData()
    }

    const handleSetHomePage = async (row) => {
      let params = {
        meetingCode: route.query.meetingCode,
        pageId: row.id,
      }
      try {
        await setExhHomePage(params)
        proxy.$baseMessage(`设置成功`, 'success', 'vab-hey-message-success')
        fetchData()
      } catch (e) {
        proxy.$baseMessage(`设置失败`, 'error', 'vab-hey-message-error')
      }
    }
    onActivated(() => {
      fetchData()
    })

    return {
      ...toRefs(state),
      handleEdit,
      queryData,
      fetchData,
      handleSizeChange,
      handleCurrentChange,
      handleDelete,
      shopStatusChange,
      handleConfig,
      Delete,
      Search,
      Plus,
      handleSetHomePage,
      handlePreview,
    }
  },
})
</script>
<style lang="scss" scoped></style>
